import { render, staticRenderFns } from "./contractok.vue?vue&type=template&id=23c5f4c2&scoped=true&"
import script from "./contractok.vue?vue&type=script&lang=js&"
export * from "./contractok.vue?vue&type=script&lang=js&"
import style0 from "./contractok.vue?vue&type=style&index=0&id=23c5f4c2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c5f4c2",
  null
  
)

export default component.exports