import { get,post } from "@/libs/axios.package";

// // 投放落地页信息查询
// export const pageInfo = (courseId,channel) => {
//   return post("/course/pageInfo", { courseId,channel});
// };
// 投放落地页信息查询
export const pageInfo = (data) => { // Cui
  return post("/order/getPageInfo", data);
};

// 问卷调查
export const askList = (questionId) => {
  return post("/question/query", {questionId});
};

// 问卷调查2
export const askList2 = (questionType) => {
  return post("/question/index",{questionType});
};
// 提交问卷调查
export const submitAnswer = (questionId, titleList) => {
  return post("/question/submit", {
    questionId, titleList
  });
};
// 提交问卷调查2
export const submitAnswer2 = (answerList,questionType) => {
  return post("/question/submit", {
    answerList,questionType
  });
};
// 进阶课问卷
export const askJinjieList = (questionType) => {
  return post("/question/jinjie",{"questionType":questionType});
};
// 提交进阶课问卷调查
export const submitJinjieAnswer = answerList => {
  return post("/question/submitJinjie", {
    answerList
  });
};

////调课
export const nextSecfindTraineeCoursetion = (packageId) => {
  return get('/customerCourse/findTraineeCourse',{packageId})
}
//提交调课信息
// /course/v2/saveTransferApplication
export const saveTransferApplication = (courseId,courseNewName,adjustTime,adjustReason) => {
  return post('/customerCourse/saveTransferApplication',{courseId,courseNewName,adjustTime,adjustReason})
}

//直播白名单添加
// /huantuo/applyWhiteList
export const applyWhiteList = ()=> {
  return post("/huantuo/applyWhiteList");
};
export const getWechatLoginData = (data)=> {
  return post("/login/getWechatLoginData",data);
};

//签署链接
// export const createSignTask = (data)=> {
//   return post("/student/createSignTask",data);
// };

// export const getActorUrl = (data)=> {
//   return post("/student/getActorUrl",data);
// };



//获取签署链接

export const getSignUrl = (data)=> {
  return post("/contract/getSignUrl",data);
};

//状态填写


