<template>
    <div class="body" style="padding-top: 20px;background-color: white;min-height: 100vh;">
        <div class="img">
            <svg t="1697259874326" style="width: 100%;" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10657" id="mx_n_1697259874328" width="180" height="180"><path d="M659.66 656.9m-300.58 0a300.58 300.58 0 1 0 601.16 0 300.58 300.58 0 1 0-601.16 0Z" fill="#E03B5D" p-id="10658"></path><path d="M819.7 882.24H225.4A139.54 139.54 0 0 1 86 742.86V320.48a139.54 139.54 0 0 1 139.38-139.4h594.32a139.56 139.56 0 0 1 139.4 139.4v422.38a139.54 139.54 0 0 1-139.4 139.38zM225.4 233.08a87.48 87.48 0 0 0-87.4 87.4v422.38a87.48 87.48 0 0 0 87.38 87.38h594.32a87.48 87.48 0 0 0 87.4-87.38V320.48a87.48 87.48 0 0 0-87.4-87.4z" fill="#231815" p-id="10659"></path><path d="M933.1 474H112a26 26 0 0 1 0-52h821.1a26 26 0 0 1 0 52z" fill="#231815" p-id="10660" data-spm-anchor-id="a313x.search_index.0.i8.366e3a812RspKN" class="selected"></path><path d="M313.12 118.18m39.68 0l0.02 0q39.68 0 39.68 39.68l0 135.16q0 39.68-39.68 39.68l-0.02 0q-39.68 0-39.68-39.68l0-135.16q0-39.68 39.68-39.68Z" fill="#231815" p-id="10661"></path><path d="M352.82 333.68a40.74 40.74 0 0 1-40.7-40.68V157.86a40.7 40.7 0 0 1 81.38 0v135.14a40.72 40.72 0 0 1-40.68 40.68z m0-214.5a38.72 38.72 0 0 0-38.7 38.68v135.14a38.7 38.7 0 0 0 77.38 0V157.86a38.72 38.72 0 0 0-38.68-38.68z" fill="#231815" p-id="10662"></path><path d="M636.1 118.18m39.68 0l0.02 0q39.68 0 39.68 39.68l0 135.16q0 39.68-39.68 39.68l-0.02 0q-39.68 0-39.68-39.68l0-135.16q0-39.68 39.68-39.68Z" fill="#231815" p-id="10663"></path><path d="M675.78 333.68a40.72 40.72 0 0 1-40.68-40.68V157.86a40.68 40.68 0 0 1 81.36 0v135.14a40.72 40.72 0 0 1-40.68 40.68z m0-214.5a38.72 38.72 0 0 0-38.68 38.68v135.14a38.68 38.68 0 0 0 77.36 0V157.86a38.7 38.7 0 0 0-38.68-38.68zM496 780a82.8 82.8 0 0 1-50.82-17.34l-89.6-69.7a26 26 0 0 1 32-41.04l89.6 69.7a30.88 30.88 0 0 0 32.52 3.24l197.34-205.46a26 26 0 0 1 37.5 36L542.18 766l-2.8 1.74A82.56 82.56 0 0 1 496 780z" fill="#231815" p-id="10664"></path></svg>
        </div>
        <div class="titile">合同签署成功</div>
        <div class="titile2">非常感谢您的信任与支持</div>
    </div>
</template>
    
<script>
import Vue from 'vue';
import { Empty } from 'vant';

Vue.use(Empty);
export default {
    name: "ask",
    data() {
        return {
            value: '',
            data: {},
        };
    },
    methods: {

    },
    mounted() {

    },
};
</script>
    
<style lang="scss" scoped>
.titile{
    width: 100%;
    font-size: 25px;
    color:rgb(224, 32, 32);
    text-align: center;
    font-weight: bold;
    margin-top: 40px;
}
.titile2{
    width: 100%;
    font-size: 16px;
    color:#999;
    text-align: center;
    margin-top: 15px;

}
.img{
    width: 200px;
    margin: 0 auto;
}
</style>
    