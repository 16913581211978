<template>
    <div class="body" style="padding-top: 20px;">
        <van-form @submit="onSubmit">
            <div class="item" id="realName">
                <p>*<span>姓名</span></p>
                <div>
                    <van-cell-group>
                        <van-field v-model="data.studentName" placeholder="请输入姓名"
                            :rules="[{ required: true, message: '姓名不能为空' }]" />
                    </van-cell-group>
                </div>
            </div>
            <div class="item" id="realName">
                <p>*<span>身份证号</span></p>
                <div>
                    <van-cell-group>
                        <van-field v-model="data.idCard" placeholder="请输入身份证号"
                            :rules="[{ required: true, message: '身份证号码不能为空' }]" />
                    </van-cell-group>
                </div>
            </div>
            <div class="item" id="realName">
                <p>*<span>手机号</span></p>
                <div>
                    <van-cell-group>
                        <van-field v-model="data.studentMobile" placeholder="手机号请认真填写,用于收验证码"
                            :rules="[{ required: true, message: '身份证号码不能为空' }]" />
                    </van-cell-group>
                </div>
            </div>
            <!-- <div class="item" id="realName">
                <p>*<span>地址</span></p>
                <div>
                    <van-cell-group>
                        <van-field v-model="data.logisticsAddress" rows="2" autosize type="textarea" maxlength="50"
                            placeholder="请输入详细地址" :rules="[{ required: true, message: '详细地址不能为空' }]" show-word-limit />
                    </van-cell-group>
                </div>
            </div> -->
            <div class="btn1">
                <van-button round block :loading="isloading" loading-text="加载中..." type="info"
                    native-type="submit">前往签署合同</van-button>
            </div>
        </van-form>
        <div class="title2"
            style="width: 90%;margin: 40px auto; text-align: left;line-height: 20px;font-size: 12px;padding-top: 20px;color: #999;">
            此信息只用于合同签署,遵循《个人隐私保护协议》,请放心填写。
        </div>
        <!-- 
        <van-cell-group>
            <van-field v-model="value" label="姓名" placeholder="请输入姓名" />
        </van-cell-group>
        <van-cell-group>
            <van-field v-model="value" label="身份证号" placeholder="请输入身份证号" />
        </van-cell-group>
        <van-cell-group>
            <van-field v-model="value" label="手机号" placeholder="请输入手机号" />
        </van-cell-group> -->


    </div>
</template>
    
<script>
import Vue from 'vue';
import { Field } from 'vant';
Vue.use(Field);
import "vant/lib/index.css";
import { studentsave, getinfo ,getsignStatus} from "@/api/xicaiquan";

import { createSignTask, getActorUrl, getSignUrl } from '@/api/activity'
import axios from 'axios'
export default {
    name: "ask",
    data() {
        return {
            value: '',
            isloading:false,
            data: {
                templateId: this.$route.query.templateId
            },
        };
    },
    methods: {
        onSubmit(values) {
            console.log('submit', values);
            this.isloading=true
            getSignUrl(this.data).then(res => {

                if(res.code=='200'){
                    console.log(res,'===')
                    // return
                    window.location.href=res.data
                }
                console.log(res, '===')
            })
        },
        getpagedata(){
            getsignStatus().then(res=>{
                if(res.data=='2'){
                    this.$router.push({
                        path:'/contractok'
                    })
                }
            })
        }
        // getCurrentTimestamp() {
        //     var now = new Date();
        //     var timestamp = now.getTime();
        //     return timestamp;
        // },

        // isTimestampValid(timestamp) {
        //     var now = new Date();
        //     var currentTimestamp = now.getTime();
        //     var difference = Math.abs(timestamp - currentTimestamp);

        //     // 检查时间戳与当前时间戳的差值是否在范围内
        //     if (difference <= 300000) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // },


        // // console.log(unixTimestamp);

        // // var isValid = isTimestampValid(unixTimestamp);
        // onabort2() {
        //     var unixTimestamp = this.getCurrentTimestamp();
        //     // 设置请求头
        //     const headers = {
        //         'X-FASC-Sign-Type': 'HMAC-SHA256',
        //         'X-FASC-App-Id': '80000308',
        //         'X-FASC-Sign': '1e94ae90e395ceb0bf94f7a0cd2e01d0',
        //         'X-FASC-Timestamp': this.isTimestampValid(unixTimestamp),
        //         'X-FASC-Nonce': '1e94ae90e395ceb0bf94f7a0cd2e01d0',
        //         'X-FASC-Grant-Type': 'client_credential',
        //         'X-FASC-Api-SubVersion': ''
        //     };

        //     // 设置请求体
        //     const data = {
        //         // 请求体数据
        //     };

        //     // 发送post请求
        //     axios.post('https://uat-api.fadada.com/api/v5/service/get-access-token', data, { headers })
        //         .then(response => {
        //             // 请求成功的处理
        //             console.log(response.data);
        //         })
        //         .catch(error => {
        //             // 请求失败的处理
        //             console.error(error);
        //         });
        //     // axios
        // },
        // onabort() {
        //     // 1695461031432133336

        //     // return
        //     createSignTask({
        //         signTemplateId: '1695447746146120630',
        //         identNameForMatch: '李栓栓',
        //         actorName: '参与方2',
        //         certType: 'id_card',
        //         actorId: '参与方2',
        //         certNoForMatch: '410928200201163315'
        //     }).then(res => {
        //         getActorUrl({ actorId: '参与人2' }).then(res => {
        //             console.log(res, '===')
        //         })
        //         console.log(res, '=====')
        //     })
        // }
    },
    mounted() {
        this.getpagedata()
    },
};
</script>
    
<style lang="scss" scoped>
.body {
    width: 100%;
    min-height: 100vh;
    background-color: white;
}

.btn1 {
    width: 80%;
    height: 40px;
    margin: 0 auto;
    background: rgb(224, 32, 32);
    color: white;
    text-align: center;
    line-height: 40px;
    border-radius: 10px;
    margin-top: 20px;

    >button {
        width: 100%;
        background: rgba(1, 1, 1, 0);
        border: none;
    }

    // background: ;
}

.item {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;

    >p {
        color: rgb(224, 32, 32);
        font-size: 15px;
        font-weight: bold;
        line-height: 30px;
        margin-bottom: 10px;

        >span {
            // color: #FE620E;
            color: #717171;
            font-size: 15px;
            font-weight: bold;
            margin-left: 5px;
        }
    }
}

.item /deep/ .van-cell {
    border: 1px #c3c3c3 solid;
    border-radius: 7px;
}

.item /deep/ .van-cell .van-field__body {
    font-size: 16px;
}

.item>div /deep/ .van-popup .van-picker__toolbar .van-picker__cancel {
    visibility: hidden;
}
</style>
    