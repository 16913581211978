<template>
  <div class="qs-bg" id="body" v-if="isshow">
    <p class="notice">
      <img src="@/assets/banner.jpg" alt=""
        style="width: 100%;">
    </p>
    <div class="title"
      style="font-size: 24px;width: 100%;text-align: center;line-height: 40px;font-weight:bold;padding-top: 10px;">报名信息
    </div>
    <div class="title2"
      style="width: 90%;margin: 0 auto; text-align: left;line-height: 20px;font-size: 14px;padding-top: 20px;">
      请按照表单内容正确填写您的报考相关信息，快递地址以信息为准，若临时有变动，请联系自己的班主任</div>
    <div class="item" id="realName">
      <p>*<span>姓名</span></p>
      <div>
        <van-cell-group>
          <van-field v-model="data.realName" placeholder="请输入姓名" />
        </van-cell-group>
      </div>
    </div>
    <div class="item" id="sex">
      <p>*<span>性别</span></p>
      <div style="border: 1px #c3c3c3 solid;padding: 12px;border-radius: 7px;">
        <van-radio-group v-model="data.sex" direction="horizontal">
          <van-radio name="0" checked-color="#FF921A">男</van-radio>
          <van-radio name="1" checked-color="#FF921A" style="margin-left: 40px">女</van-radio>
        </van-radio-group>
      </div>
    </div>
    <div class="item" id="address">
      <p>*<span>快递资料邮寄地址</span></p>
      <div>
        <van-field readonly is-link clickable name="picker" :value="data.address" placeholder="请选择"
          @click="showPicker = true" />
        <van-popup v-model="showPicker" position="bottom">
          <van-area title="请选择省市区" @confirm="onConfirm" @cancel="address = false" :area-list="areaList" />
        </van-popup>
      </div>
      <div style="margin-top: 20px;" id="logisticsAddress">
        <van-field v-model="data.logisticsAddress" rows="2" autosize type="textarea" maxlength="50"
          placeholder="此地址为快递资料的填写地址,务必填写正确" show-word-limit />
      </div>
    </div>

    <div class="item" id="mobile">
      <p>*<span>手机号</span></p>
      <div>
        <van-cell-group>
          <van-field v-model="data.mobile" placeholder="请输入手机号" />
        </van-cell-group>
      </div>
    </div>

    <div class="item" id="koseki">
      <p>*<span>户籍所在地(省份)</span></p>
      <div>
        <van-cell-group>
          <van-field v-model="data.koseki" placeholder="请输入" />
        </van-cell-group>
      </div>
    </div>

    <div class="item" id="kosekiCity">
      <p>*<span>户籍所在地(城市)</span></p>
      <div>
        <van-cell-group>
          <van-field v-model="data.kosekiCity" placeholder="请输入" />
        </van-cell-group>
      </div>
    </div>
    <div class="item" id="educationBackground">
      <p>*<span>最高学历</span></p>
      <div>
        <van-field readonly is-link clickable name="picker" :value="data.educationBackground" placeholder="请选择"
          @click="showPicker3 = true" />
        <van-popup v-model="showPicker3" position="bottom">
          <van-picker title="最高学历" show-toolbar :columns="columns" @confirm="onConfirm3" />
        </van-popup>
      </div>
    </div>
    <div class="item" id="major">
      <p>*<span>专业</span></p>
      <div>
        <van-cell-group>
          <van-field v-model="data.major" placeholder="请输入" />
        </van-cell-group>
      </div>
    </div>
    <div class="item" id="idAttributes">
      <p>*<span>身份属性</span></p>
      <div>
        <van-field readonly is-link clickable name="picker" :value="data.idAttributes" placeholder="请选择"
          @click="showPicker4 = true" />
        <van-popup v-model="showPicker4" position="bottom">
          <van-picker title="身份属性" show-toolbar :columns="columns2" @confirm="onConfirm4" />
        </van-popup>
      </div>
    </div>


    <div class="item" id="partyMember">
      <p>*<span>是否党员</span></p>
      <div>
        <van-radio-group v-model="data.partyMember" direction="horizontal">
          <van-radio name="Y" checked-color="#FF921A">是</van-radio>
          <van-radio name="N" checked-color="#FF921A" style="margin-left: 40px">否</van-radio>
        </van-radio-group>
      </div>
    </div>
    <div class="item" id="retirement">
      <p>*<span>是否有过服兵役经历？</span></p>
      <div>
        <van-radio-group v-model="data.retirement" direction="horizontal">
          <van-radio name="Y" checked-color="#FF921A">是</van-radio>
          <van-radio name="N" checked-color="#FF921A" style="margin-left: 40px">否</van-radio>
        </van-radio-group>
      </div>
    </div>
    <div class="btn-wrap">
      <!-- <van-button :loading="false" type="primary" /> -->
      <!-- <van-button loading type="primary" loading-type="spinner" /> -->
      <van-button :loading="btnLoading" :disabled="btnLoading" loading-type="spinner" loading-text="提交中..."
        @click.stop="handleSubmit">确认提交</van-button>
    </div>
  </div>
</template>
  
<script>
import Vue from "vue";
import { Field } from "vant";
import { RadioGroup, Radio } from "vant";
import areaList from "@/libs/111.js";
import { Area } from "vant";
import { DropdownMenu, DropdownItem } from 'vant';
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
import { DatetimePicker } from "vant";
Vue.use(DatetimePicker);
Vue.use(Area);
Vue.use(Radio);
Vue.use(RadioGroup);
import { Picker } from "vant";
Vue.use(Picker);
Vue.use(Field);
import "vant/lib/index.css";
import { Toast } from "vant";
import { studentsave, getinfo, getsignStatus } from "@/api/xicaiquan";
import { type } from "os";
export default {
  name: "ask",
  data() {
    return {
      value1: 0,
      columns: ["全日制本科", "非全日制本科", "全日制大专", "非全日制大专", "硕士研究生", "博士研究生", "高中", '中专', '其他'],
      columns2: ['应届毕业生', '非应届毕业生', '社会人才'],
      showPicker4: false,
      areaList,
      isshow: false,
      data: {
        studentType: '1',
        address: "",
        educationBackground: "",

        idAttributes: "",
        koseki: "",
        kosekiCity: "",
        logisticsAddress: "",
        major: "",
        mobile: "",
        partyMember: "",
        realName: "",
        retirement: "",
        sex: "",
      },
      showPicker: false,
      showPicker3: false,
      message: "",
      btnLoading: false,
      datalist: [
        {
          con: "",
          titile: "快递邮寄地址",
          contype: "address",
        },
        {
          con: "",
          titile: "最高学历",
          contype: "educationBackground",
        },
        {
          con: "",
          titile: "身份属性",
          contype: "idAttributes",
        },
        {
          con: "",
          titile: "户籍所在省",
          contype: "koseki",
        },
        {
          con: "",
          titile: "户籍市区",
          contype: "kosekiCity",
        },
        {
          con: "",
          titile: "详细地址",
          contype: "logisticsAddress",
        },
        {
          con: "",
          titile: "专业",
          contype: "major",
        },
        {
          con: "",
          titile: "手机号",
          contype: "mobile",
        },
        {
          con: "",
          titile: "是否党员",
          contype: "partyMember",
        },
        {
          con: "",
          titile: "真实姓名",
          contype: "realName",
        },
        {
          con: "",
          titile: "是否退役",
          contype: "retirement",
        },
        {
          con: "",
          titile: "性别",
          contype: "sex",
        },
      ],
    };
  },
  methods: {
    onConfirm(value) {
      console.log(value, "===");
      console.log(value, "====");
      this.data.address = "";
      for (let i = 0; i < value.length; i++) {
        if (!value[i].name) {
          Toast("请选择");
          return;
        }
        this.data.address += value[i].name;
        this.showPicker = false;
      }
    },

    onConfirm3(value) {
      this.data.educationBackground = "";
      this.data.educationBackground = value;
      this.showPicker3 = false;
    },
    onConfirm4(value) {
      this.data.idAttributes = "";
      this.data.idAttributes = value;
      this.showPicker4 = false;
    },
    getAskList() {
      // this.data.formId=this.$route.query.formId
      // studentsave(this.data).then(res => {
      //   console.log(res, '===')
      // })
      // return
      getsignStatus().then(res => {
        // 0 未填写问卷
        // 1 未签署合同
        // 2 已经签署合同
        if (res.code == '200') {
          if (res.data == '0') {
            this.isshow = true
          }
          else if (res.data == '1') {
            if (this.$route.query.linkType != '3') {
              this.isshow = false
              Toast('问卷已经填写过')
              return
            }
            this.$router.push({
              path: '/concat',
              query: {
                templateId: this.$route.query.templateId
              }
            })
          } else {
            this.$router.push({
              path: '/contractok',
              query: {
                templateId: this.$route.query.templateId
              }
            })
          }
        }

        console.log(res, '核实个人消息')
      })
    },
    getarrtitle(num) {
      // console.log('调用我了',num)
      var title = "";
      for (let i = 0; i < this.datalist.length; i++) {
        if (this.datalist[i].contype == num) {
          title = this.datalist[i].titile;
          return title;
        }
      }
    },
    handleSubmit() {
      console.log(this.$route.query.studentType, '===')
      // return
      for (var index in this.data) {
        var title = this.getarrtitle(index);
        if (this.data[index] == "") {
          Toast(`${title}不能为空`);
          console.log(index)
          document.getElementById(`${index}`).scrollIntoView();
          return;
        }
      }
      console.log(this.data, "----");
      this.btnLoading = true;
      this.data.formId = this.$route.query.formId
      studentsave(this.data).then((res) => {
        this.btnLoading = false;
        if (res.code == '200') {
          this.isshow=false
          if(this.$route.query.linkType=='3'){
            this.getAskList()
          }
          Toast("感谢您的参与");
        }
      });
    },
  },
  mounted() {
    this.getAskList();
  },
};
</script>
  
<style lang="scss" scoped>
.qs-bg {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: #fff;
  padding-bottom: 30px;
  // padding-top: 40px;
}

.ask-bg {
  width: 100%;
  height: 1.8rem;
}

.notice {
  width: 100%;
  //   height: 1.9rem;
  // margin: 0.34rem auto 0 auto;
  margin: 0 auto;
  // background: rgba(#FE8000, .05);

  // background: #fff8f3;
  border-radius: 9px;
  // border: 1px solid #fecd8e;
  font-size: 0.14rem;
  // font-family: PingFang-SC-Regular, PingFang-SC;
  // font-weight: 400;
  // color: #fe620e;/
  // line-height: 0.3rem;
  // padding: 0.1rem 0.1rem;
}

.item {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;

  >p {
    color: rgb(224, 32, 32);
    font-size: 15px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 10px;

    >span {
      // color: #FE620E;
      color: #717171;
      font-size: 15px;
      font-weight: bold;
      margin-left: 5px;
    }
  }
}

.item /deep/ .van-cell {
  border: 1px #c3c3c3 solid;
  border-radius: 7px;
}

.item /deep/ .van-cell .van-field__body {
  font-size: 16px;
}

.item>div /deep/ .van-popup .van-picker__toolbar .van-picker__cancel {
  visibility: hidden;
}

.btn-wrap {
  //   position: fixed;
  //   z-index: 998;
  width: 100%;
  height: 0.7rem;
  margin-top: 40px;

  >button {
    display: block;
    width: 3.24rem;
    height: 0.48rem;
    background: rgb(224, 32, 32);
    // box-shadow: 0px 5px 10px 0px rgba(#fe8000, 0.31);
    border-radius: 10px;
    font-size: 17px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    margin: 0.1rem auto;
  }
}

.btn-wrap /deep/ .van-button .van-button__content {
  font-size: 18px;
}

.textarea {
  width: 3.15rem;
  // height: 1.22rem;
  border-radius: 4px;
  border: 1px solid rgba(189, 189, 189, 1);
  border: 1px solid rgba(189, 189, 189, 1);
  margin-top: 0.24rem;
}

.mytext {
  /deep/.van-field__label {
    display: none;
  }
}
</style>
  